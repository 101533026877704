import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

// CSS
import 'styles/HomePage.scss'

// Components
import { BannerDefault, TitleDefault } from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import ContactForm from '../components/ContactForm'

// Images
import arrow from '../img/arrow-menu.svg'
import arrowWhite from '../img/arrow-white.svg'
import jeroen from '../img/jeroen.png'

function HomePage({ data }) {
  const { wordpressPage: page } = data
  const homeHeader = page.acf.header
  const watIs = page.acf.wat_is_kavelruil
  const homeAuthor = page.acf.about_author

  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} />
      <div className="home-page">
        <BannerDefault className="py-5">
          <div className="row">
            <Img
              className="position-absolute banner-image"
              fluid={
                homeHeader.image.localFile.childImageSharp.fluid
              }
            />
            <div className="col-lg-5 pb-4">
              <TitleDefault>
                <span>{homeHeader.textFirst}</span>
                <span>{homeHeader.textLast}</span>
              </TitleDefault>
            </div>
          </div>
        </BannerDefault>
        <section className="home-intro">
          <div className="container">
            <div className="row py-4 align-items-center">
              <div
                className="col-lg-7 mb-3 mb-lg-0"
                dangerouslySetInnerHTML={{ __html: homeHeader.subHeaderText }}
              />
              <div className="col-lg-5">
                <Link to={homeHeader.subHeaderButtonLink} className="btn">
                  {homeHeader.subHeaderButtonText}
                  <img
                    src={arrow}
                    alt="arrow"
                    width="18"
                    className="button-img"
                  />
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="wat-is-kavelruil">
          <div className="container py-lg-5">
            <div className="row py-5">
              <div className="col-lg-7">
                <h2 className="title">
                  <img src={arrow} width="18" alt="arrow" />
                  {watIs.title}
                </h2>
                <div dangerouslySetInnerHTML={{ __html: watIs.text }} />
                <p className="float-right">
                  <Link to={watIs.button_link} className="btn btn-transp">
                    {watIs.button_text}
                    <img
                      src={arrow}
                      alt="arrow"
                      width="18"
                      className="button-img"
                    />
                  </Link>
                </p>
              </div>
              <div className="col-lg-1 d-lg-block" />
              <div className="col-lg-4">
                <h2>{watIs.form_title}</h2>
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
        <section className="over-de-auteur">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h2 className="title">
                  <img src={arrowWhite} width="18" alt="arrow" />
                  <span>{homeAuthor.title}</span>
                  <small>{homeAuthor.name}</small>
                </h2>
                <div
                  dangerouslySetInnerHTML={{ __html: homeAuthor.text }}
                />
                <p className="float-right">
                  <a href={homeAuthor.buttonLink} target="_blank" rel="noopener noreferrer" className="btn">
                    {homeAuthor.buttonText}
                    <img
                      src={arrow}
                      alt="arrow"
                      width="18"
                      className="button-img"
                    />
                  </a>
                </p>
              </div>
              <div className="col-lg-2 d-lg-block" />
              <div className="col-lg-3">
                <img src={jeroen} width="320" alt="Jeroen" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomePage {
    wordpressPage(slug: { eq: "home" }) {
      title
      acf {
        header {
         image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1800) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          textFirst: text_first
          textLast: text_last
          subHeaderText: subheader_text
          subHeaderButtonText: subheader_button_text
          subHeaderButtonLink: subheader_button_link
        }
        wat_is_kavelruil {
          form_title
          text
          title
          button_text
          button_link
        }
        about_author {
          title
          name
          text
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 339) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          buttonText: button_text
          buttonLink: button_link
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`
